exports.components = {
  "component---src-components-blog-page-tsx": () => import("./../../../src/components/BlogPage.tsx" /* webpackChunkName: "component---src-components-blog-page-tsx" */),
  "component---src-pages-3-d-tsx": () => import("./../../../src/pages/3d.tsx" /* webpackChunkName: "component---src-pages-3-d-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-dialogue-editor-tsx": () => import("./../../../src/pages/DialogueEditor.tsx" /* webpackChunkName: "component---src-pages-dialogue-editor-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sentinel-beam-tsx": () => import("./../../../src/pages/SentinelBeam.tsx" /* webpackChunkName: "component---src-pages-sentinel-beam-tsx" */),
  "component---src-pages-work-tsx": () => import("./../../../src/pages/work.tsx" /* webpackChunkName: "component---src-pages-work-tsx" */)
}

